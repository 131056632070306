import React, { Component } from "react";
import Header from "./components/header";
import Main from "./components/main";
import Footer from "./components/footer";
import './styles/App.css';

function App() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}
export default App;