import React from "react";
import Gallery from "react-grid-gallery";

function Galeri() {
  const IMAGES = [
    {
      src: "assets/fotogaleri/etkinlik_2.png",
      thumbnail: "assets/fotogaleri/etkinlik_2.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
     /* tags: [
        { value: "Ocean", title: "Ocean" },
        { value: "People", title: "People" },
      ],*/
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_1.png",
      thumbnail: "assets/fotogaleri/etkinlik_1.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_3.png",
      thumbnail: "assets/fotogaleri/etkinlik_3.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_4.png",
      thumbnail: "assets/fotogaleri/etkinlik_4.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_5.png",
      thumbnail: "assets/fotogaleri/etkinlik_5.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_6.png",
      thumbnail: "assets/fotogaleri/etkinlik_6.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_7.png",
      thumbnail: "assets/fotogaleri/etkinlik_7.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_8.png",
      thumbnail: "assets/fotogaleri/etkinlik_8.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_9.png",
      thumbnail: "assets/fotogaleri/etkinlik_9.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_10.png",
      thumbnail: "assets/fotogaleri/etkinlik_10.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_11.png",
      thumbnail: "assets/fotogaleri/etkinlik_11.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_12.png",
      thumbnail: "assets/fotogaleri/etkinlik_12.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_13.png",
      thumbnail: "assets/fotogaleri/etkinlik_13.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_14.png",
      thumbnail: "assets/fotogaleri/etkinlik_14.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_15.png",
      thumbnail: "assets/fotogaleri/etkinlik_15.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_16.png",
      thumbnail: "assets/fotogaleri/etkinlik_16.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_17.png",
      thumbnail: "assets/fotogaleri/etkinlik_17.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_18.png",
      thumbnail: "assets/fotogaleri/etkinlik_18.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_19.png",
      thumbnail: "assets/fotogaleri/etkinlik_19.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_20.png",
      thumbnail: "assets/fotogaleri/etkinlik_20.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_21.png",
      thumbnail: "assets/fotogaleri/etkinlik_21.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_22.png",
      thumbnail: "assets/fotogaleri/etkinlik_22.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_23.png",
      thumbnail: "assets/fotogaleri/etkinlik_23.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_24.png",
      thumbnail: "assets/fotogaleri/etkinlik_24.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_25.png",
      thumbnail: "assets/fotogaleri/etkinlik_25.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_26.png",
      thumbnail: "assets/fotogaleri/etkinlik_26.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_27.png",
      thumbnail: "assets/fotogaleri/etkinlik_27.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
    {
      src: "assets/fotogaleri/etkinlik_28.png",
      thumbnail: "assets/fotogaleri/etkinlik_28.png",
      thumbnailWidth: 320,
      thumbnailHeight: 200,
      caption: "Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi",
    },
  ];

  return <Gallery images={IMAGES} backdropClosesModal={true} />;
}

export default Galeri;
