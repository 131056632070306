import React, { useState, window } from "react";

function Header() {
  const [toggleMenu, setToggleMenu] = useState(false);

  const closeMenu = () => {
    setToggleMenu(false); // closes it ...
  };

  return (
    <header className="header">
      <nav className="navbar container">
        <div
          className="logo"
          title="Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi"
        >
          <img src="assets/images/avcilar_logo.png"></img>
        </div>
        <div className="menu">
          <ul>
            <li>
              <a href="#anasayfa">Ana Sayfa</a>
            </li>
            <li>
              <a href="#hakkimizda">Hakkımızda</a>
            </li>
            <li>
              <a href="#nelerSagliyoruz">Neler Sağlıyoruz</a>
            </li>
            <li>
              <a href="#kuluckaMerkezi">Kuluçka Merkezi</a>
            </li>
            <li>
              <a href="#etkinlikler">Etkinlikler</a>
            </li>
            <li>
              <a href="#footer">İletişim</a>
            </li>
          </ul>
        </div>
        <div className="menu-btn">
          {toggleMenu ? (
            <i class="bx bx-menu" onClick={() => setToggleMenu(false)}></i>
          ) : (
            <i class="bx bx-menu" onClick={() => setToggleMenu(true)}></i>
          )}
          {toggleMenu && (
            <div className="menu menu-active">
              <div className="menu-close">
              <a href="" onClick={() => closeMenu()}>
                  <i class='bx bx-x'></i>
                </a>
              </div>
              <div className="menu-mobil">
                <a href="#anasayfa" onClick={() => closeMenu()}>
                  Ana Sayfa
                </a>
                <a href="#hakkimizda" onClick={() => closeMenu()}>
                  Hakkımızda
                </a>
                <a href="#nelerSagliyoruz" onClick={() => closeMenu()}>
                  Neler Sağlıyoruz
                </a>
                <a href="#kuluckaMerkezi" onClick={() => closeMenu()}>
                  Kuluçka Merkezi
                </a>
                <a href="#fotoGaleri" onClick={() => closeMenu()}>
                  Foto Galeri
                </a>
                <a href="#footer" onClick={() => closeMenu()}>
                  İletişim
                </a>
              </div>
              <div className="menu-medya">
                <i class="bx bxl-twitter"></i>
                <i class="bx bxl-youtube"></i>
                <i class="bx bxl-instagram-alt"></i>
              </div>
            </div>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
