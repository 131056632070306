import ScrollToTop from "./scrollArrow";


function Footer() {
  return (
    <footer className="footer" id="footer">
    <ScrollToTop></ScrollToTop>
      <div className="container-fluid">
        <div className="container p-0">
          <div className="row pb-5">
            <div className="col-md-5 pt-5">
              <h5 className=""> Site Hakkında</h5>
              Avcılar Belediyesi bünyesinde hayata geçirilen girişimcilere,
              öğrencilere, akademik araştırma yapanlara, öğrenmeyi ve kendini
              geliştirmeyi sevenlere açık; içerisinde ortak ve paylaşımlı
              çalışma alanları, toplantı salonları, girişimcilik, deneyim,
              inovasyon ve ortak çalışma merkezidir.
              <div className="sosyal-medya pt-5 text-center">
                <i class="bx bxl-twitter"></i>
                <i class="bx bxl-youtube"></i>
                <i class="bx bxl-instagram-alt"></i>
              </div>
            </div>
            <div className="col-md-3 text-left pt-5">
              <h5 className="">Hızlı Ulaşım</h5>
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item hizli_menu pb-1">
                  <a href="#anasayfa">Ana Sayfa</a>
                </li>
                <li className="nav-item hizli_menu pb-1">
                  <a href="#hakkimizda">Hakkımızda</a>
                </li>
                <li className="nav-item hizli_menu pb-1">
                  <a href="#nelerSagliyoruz">Neler Sağlıyoruz</a>
                </li>
                <li className="nav-item hizli_menu pb-1">
                  <a href="#kuluckaMerkezi">Kuluçka Merkezi</a>
                </li>
                <li className="nav-item hizli_menu pb-1">
                  <a href="#iletisim">
                    <i className="bi bi-chevron-right"></i> İletişim
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4 pt-5 pb-5">
              <h5 className="">İletişim</h5>
              <ul className="pb-4" style={{ padding: 0, margin: 0 }}>
                <li className="py-1 fw-bolder">
                  Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi
                </li>
                <li className="py-1 d-flex align-items-center">
                  <i class="bx bx-map footer-iconlar"></i> Üniversite mahallesi Firüzköy Bulvarı <br></br> No : 22 / 1  Avcılar / İstanbul
                </li>
                <li className="py-1 d-flex align-items-center">
                  <i class="bx bx-headphone footer-iconlar"></i> 444 6 989
                </li>
                <li className="py-1 d-flex align-items-center">
                  <i class="bx bx-mail-send footer-iconlar"></i><a href="mailto:avcim@avcilar.bel.tr">avcim@avcilar.bel.tr</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="container-fluid copyright">
        <div className="row">
          <div class="col-md-12 d-flex justify-content-center">
            <img
              src="assets/images/avcilar-belediyesi-logo.png"
              className="rounded-circle img-thumbnail"
              alt="Avcılar Belediye Başkanlığı"
              style={{ width: "100px" }}
            ></img>
            <p className="text-center">
              Tüm Hakları{" "}
              <a
                href="https://www.avcilar.bel.tr"
                title="Avcılar Belediyesi Web Sitesi"
              >
                Avcılar Belediye Başkanlığına{" "}
              </a>
              Aittir ©2022
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
