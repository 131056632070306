import React from "react";
import Galeri from "./galeri";

function Main() {
  const videoUrl = "assets/video/inovasyon_merkezi.mp4";

  return (
    <main>
      <section class="container" id="anasayfa">
        <div className="row inovasyon-merkezi-bg">
          <div className="col-lg-5 p-0 m-0">
            <video controls width="100%" style={{ width: "100%", height: "100%" }}>
              <source src={videoUrl} type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
          </div>
          <div className="col-lg-7 p-3">
            <h3 className="ana-baslik wow fadeInUp" data-wow-delay=".2s">
              Avcılar Belediyesi Uygulamalı Çözüm ve İnovasyon Merkezi
            </h3>
            <p className="wow fadeInUp p-0 m-0" data-wow-delay=".3s">
              AVÇİM, Avcılar belediyesi tarafından hayata geçirilen gençleri
              yenilikçi teknolojilerle eğiten, aldıkları eğitimlerle birlikte
              oluşan ve gelişen yenilikçi fikirlerini girişimcilik örneğine
              dönüştüren, gençlerin ülkemizin istihdam ve işgücüne katılımı
              yönünde gerçekleştirecekleri girişimleri ile çalışma hayatına
              katkıda bulunmasını sağlayan inovatif bir merkezdir.
            </p>
          </div>
        </div>
      </section>

      <section id="hakkimizda" className="container">
        <h3 className="hakkimizda wow fadeInUp" data-wow-delay=".4s">
          Hakkımızda
        </h3>
        <div className="row mt-5">
          <div className="col-md-7">
            <p className="wow fadeInUp py-4" data-wow-delay=".5s">
              Avcılar Belediyesi bünyesinde hayata geçirilen girişimcilere,
              öğrencilere, akademik araştırma yapanlara, öğrenmeyi ve kendini
              geliştirmeyi sevenlere açık; içerisinde ortak ve paylaşımlı
              çalışma alanları, toplantı salonları, girişimcilik, deneyim,
              inovasyon ve ortak çalışma merkezidir.
            </p>
            <h5 className="wow fadeInUp" data-wow-delay=".6s">
              Misyon & Vizyonumuz
            </h5>
            <p className="wow fadeInUp" data-wow-delay=".7s">
              Avçim, olarak genç girişimcileri desteklemek, İstanbul
              girişimcilik ekosistemini geliştirmek, girişimcilik ekosistemi
              (girişimciler & partnerler) ile teması kuvvetlendirmek, yüksek
              potansiyelli girişimler ile yeni teknolojileri erken tanıma ve
              farklı alanlarda yetkinlikleri olan kurumların iş birliklerinin
              geliştirmek amacı ile kurulmuştur.
            </p>
          </div>
          <div className="col-md-5 text-end">
            <img
              src="assets/images/avcim.png"
              style={{ width: "100%", height: "100%" }}
              className="wow fadeInUp"
              data-wow-delay=".8s"
            ></img>
          </div>
        </div>
      </section>

      <section id="nelerSagliyoruz" className="container">
        <h3 className="neler-sagliyoruz wow fadeInUp pb-3" data-wow-delay=".4s">
          Neler Sağlıyoruz ?
        </h3>
        <div className="row py-3 mt-5 wow fadeInUp" data-wow-delay=".5s">
          <div className="col-md-6 text-end">
            <h5 className="gradient_txt">Tam Donanımlı Çalışma Ortamı</h5>
          </div>
          <div className="col-md-6">
            Ortak çalışma alanında yer alan ve kullanıcıların uygunluğuna göre
            girişimcilere ve açık ofis kullanım hakkı vermekteyiz.
          </div>
        </div>

        <div className="row py-3 wow fadeInUp" data-wow-delay=".6s">
          <div className="col-md-6 text-end">
            <h5 className="gradient_txt">Hazır Ofis</h5>
          </div>
          <div className="col-md-6">
            Kişi veya firmaya özel olarak tahsis edilen, diğer kullanıcılara
            kapalı özel ofis hizmetidir. Sadece kullanıcıların girebildiği dört
            tarafı kapalı ve kapısı kilitlenebilen ofislerdir.
          </div>
        </div>

        <div className="row py-3 wow fadeInUp" data-wow-delay=".7s">
          <div className="col-md-6 text-end">
            <h5 className="gradient_txt">Toplantı Odası</h5>
          </div>
          <div className="col-md-6">
            Her türlü donanıma sahip salonumuzda iç ve dış paydaşlarla yapılacak
            toplantılarda, misafirlerinizin ağırlanmasında, sunumlarınızda ve
            çalışmalarında rahatlıkla kullanılabileceğiniz bir ortam
            sunulmaktadır.
          </div>
        </div>

        <div className="row py-3 wow fadeInUp" data-wow-delay=".8s">
          <div className="col-md-6 text-end">
            <h5 className="gradient_txt">Modüler Etkinlik ve Eğitim Salonu</h5>
          </div>
          <div className="col-md-6">
            Kullanıcıların ve girişimcilik ekosistemi paydaşlarının, seminer ve
            eğitim gibi inovasyon ve girişimcilik etkinliklerini
            gerçekleştirebilecekleri alanlardır.
          </div>
        </div>

        <div className="row py-3 wow fadeInUp" data-wow-delay=".9s">
          <div className="col-md-6 text-end">
            <h5 className="gradient_txt">Mentorluk ve Danışmanlık Ofisi</h5>
          </div>
          <div className="col-md-6">
            Girişimcilik ekosisteminden ünlü isimlerin AVÇİM’de konuk edilerek,
            genç girişimcilerle birlikte çalışmalar yürütmelerinin
            sağlayacatır., Ulusal ve uluslararası düzeyde iş dünyasının
            deneyimli profesyonellerini, üniversitelerdeki akademisyenleri
            girişimcilerle bir araya getirerek mentorluk yapmalarına olanak
            sağlanan bu ortam, Girişimlerin ulusal ve uluslararası teşvikler ve
            fonlara erişimine aracılık edilmesi, başvuruların sağlıklı bir
            şekilde yapılması ve sonuca ulaşılması konularında destek
            sağlayacaktır.
          </div>
        </div>
      </section>

      <section id="kuluckaMerkezi" className="container">
        <h3
          className="kulucka-merkezi wow fadeInUp"
          data-wow-delay=".4s"
          style={{ padding: 100 }}
        >
          AVCİM Kuluçka Merkezi
        </h3>
        <div className="row">
          <div className="col-md-4 mb-5 wow fadeInUp" data-wow-delay=".5s">
            <div className="gradient_bar"></div>
            Avçim’de proje/iş fikri başarılı bulunan kişilere kurdukları işi
            veya proje fikrini geliştirmeleri için açık ofiste çalışma imkânı
            sunulur. Bu süreçte girişimciler finans kaynaklarına erişim imkânı,
            iş yeri mekânı, ortak ofis ekipmanı, ofis hizmetleri gibi iş
            geliştirme desteği alır.
          </div>
          <div className="col-md-4 mb-5 wow fadeInUp" data-wow-delay=".6s">
            <div className="gradient_bar"></div>
            Girişimcilik ekosisteminin en büyük eksikliği olan büyük firma-yeni
            girişim etkileşiminin yaratılmasıyla, büyük şirketin bünyesindeki
            «bir soruna bir girişimci tarafından çözüm üretilmesi» modeli de
            yine İnovasyon Merkezimiz bünyesinde hayata geçecektir.
          </div>
          <div className="col-md-4 mb-5 wow fadeInUp" data-wow-delay=".7s">
            <div className="gradient_bar"></div>
            İnovasyon Merkezimizde şirketlerin Ar-Ge ve inovasyon merkezlerini
            buraya çekerek onlara fiziksel altyapıyı sağlarken, merkezimizin
            İstanbul’daki üniversiteler, şirketler, melek yatırımcılar ve diğer
            paydaşlar arasında bir köprü vazifesi görmesi hedeflenmektedir.
          </div>
          <div className="col-md-4 mb-5 wow fadeInUp" data-wow-delay=".8s">
            <div className="gradient_bar"></div>
            Girişimciler ile paydaşların eşleşmeleri İnovasyon Merkezi
            bünyesinde sağlanacaktır.
          </div>
          <div className="col-md-4 mb-5 wow fadeInUp" data-wow-delay=".9s">
            <div className="gradient_bar"></div>
            Girişimcilere yönelik hızlandırma programları, ticarileşme, büyüme
            desteği sağlamaktadır.
          </div>
          <div className="col-md-4 mb-5 wow fadeInUp" data-wow-delay="1s">
            <div className="gradient_bar"></div>
            Girişimciler ile paydaşların eşleşmeleri İnovasyon Merkezi
            bünyesinde sağlanacaktır.
          </div>
        </div>
      </section>

      <section id="etkinlikler" className="container">
        <h3
          className="etkinlikler wow fadeInUp"
          data-wow-delay=".4s"
          style={{ padding: 100 }}
        >
          Etkinlikler
        </h3>
        <div className="row">
          <div className="col-md-12 mb-5 wow fadeInUp" data-wow-delay=".5s">
            <Galeri />
          </div>
        </div>
      </section>
    </main>
  );
}

export default Main;
